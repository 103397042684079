.mainContainer {
  min-height: 100vh;
  padding-top: 10px;
  padding-bottom: 10px;
}

.MuiTableCell-root {
  font-size: 10pt;
}

.floating-button {
  position: fixed;
  display: flex;
  bottom: 2%;
  right: 2%;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 50%;
  padding: 5px;
  z-index: 999;
  -webkit-backface-visibility: hidden;
}

.full-modal {
  width: 90%;
}

.mini-modal {
  width: 30%;
}
