div.full {
  flex: 1;
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 992px) {
  div.half {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
}

div.flexDirectionRow {
  display: flex;
  flex-direction: row;
}

div.space-between {
  justify-content: space-between;
}

div.center {
  background-color: '#000';
  display: flex;
  align-items: center;
  justify-content: center;
}

.container-wrapper {
  padding: 30px 40px;
}

.custom-dialog {
  width: 90%;
  height: 50%;
}
